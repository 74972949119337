import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../config.json"; // Ваш config с сервисами
import { clearToken } from "../../scripts/token"; // Ваш скрипт для очистки токена

const StoreUploadLimit = () => {
    const params = useParams();
    const merchantId = params.id; // получаем merchantId из URL
    const [uploadLimit, setUploadLimit] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchUploadLimit();  // получаем данные лимита загрузки при монтировании компонента
    }, [merchantId]);

    // Получаем лимит загрузки по merchantId
    const fetchUploadLimit = () => {
        axios
            .get(`${config.service}/api/kaspi-store-upload-limit/${merchantId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                },
            })
            .then((response) => {
                setUploadLimit(response.data);  // сохраняем данные лимита в state
            })
            .catch((err) => {
                console.error("Error fetching upload limit:", err);
                if (err?.response?.status === 401) {
                    clearToken();  // очистка токена, если статус 401
                }
            })
            .finally(() => {
                setIsLoading(false);  // завершение загрузки
            });
    };

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <div>
                    <h2>Лимит запросов</h2>
                    {uploadLimit ? (
                        <div>
                            <p><strong>Количество запросов:</strong> {uploadLimit.uploadedCount}/{uploadLimit.maxCount}</p>
                            <p><strong>Дата окончания срока:</strong> {new Date(uploadLimit.expirationDate).toLocaleTimeString()}</p>
                        </div>
                    ) : (
                        <p>No data available</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default StoreUploadLimit;
